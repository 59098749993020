<template>
    <v-container class="fill-height" max-width="600px">
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="6">
                <v-card class="elevation-10">
                    <v-toolbar
                        color="error"
                        dark
                        flat
                        dense
                    >
                        <v-toolbar-title>Authentication Error </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-icon x-large color="error lighten-2">mdi-emoticon-frown-outline</v-icon>
                        We're having difficulty authenticating you. Please try again later.
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
// import { computed, ref } from '@vue/composition-api';

export default {
    setup() {
    }
}
</script>