import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height",attrs:{"max-width":"600px"}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"8","md":"6"}},[_c(VCard,{staticClass:"elevation-10"},[_c(VToolbar,{attrs:{"color":"error","dark":"","flat":"","dense":""}},[_c(VToolbarTitle,[_vm._v("Authentication Error ")])],1),_c(VCardText,[_c(VIcon,{attrs:{"x-large":"","color":"error lighten-2"}},[_vm._v("mdi-emoticon-frown-outline")]),_vm._v(" We're having difficulty authenticating you. Please try again later. ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }